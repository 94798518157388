body {
  background: #e7e7e7;
  color: #293c4b;
  font-family: 'Source Sans Pro', 'Trebuchet MS', 'Lucida Grande', 'Bitstream Vera Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  text-align: center; }

.game-controls > * {
  display: block; }

.game-controls.learn {
  margin-top: 1rem; }

.game-controls .chord-tone-legend button,
.game-controls .scale-tone-legend button,
.game-controls .mode-buttons button,
.game-controls .string-set-buttons button,
.game-controls .quality-buttons button,
.game-controls .inversion-buttons button, .game-controls .note-buttons button {
  background: #fff;
  border: none;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-sizing: border-box;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.21); }
  .game-controls .chord-tone-legend button.active,
  .game-controls .scale-tone-legend button.active,
  .game-controls .mode-buttons button.active,
  .game-controls .string-set-buttons button.active,
  .game-controls .quality-buttons button.active,
  .game-controls .inversion-buttons button.active, .game-controls .note-buttons button.active {
    border: 3px solid #0364bf; }
  .game-controls .chord-tone-legend button.correct,
  .game-controls .scale-tone-legend button.correct,
  .game-controls .mode-buttons button.correct,
  .game-controls .string-set-buttons button.correct,
  .game-controls .quality-buttons button.correct,
  .game-controls .inversion-buttons button.correct, .game-controls .note-buttons button.correct {
    border: 3px solid green; }
  .game-controls .chord-tone-legend button.incorrect,
  .game-controls .scale-tone-legend button.incorrect,
  .game-controls .mode-buttons button.incorrect,
  .game-controls .string-set-buttons button.incorrect,
  .game-controls .quality-buttons button.incorrect,
  .game-controls .inversion-buttons button.incorrect, .game-controls .note-buttons button.incorrect {
    border: 3px solid red; }
  .game-controls .chord-tone-legend button:active,
  .game-controls .scale-tone-legend button:active,
  .game-controls .mode-buttons button:active,
  .game-controls .string-set-buttons button:active,
  .game-controls .quality-buttons button:active,
  .game-controls .inversion-buttons button:active, .game-controls .note-buttons button:active, .game-controls .chord-tone-legend button:focus,
  .game-controls .scale-tone-legend button:focus,
  .game-controls .mode-buttons button:focus,
  .game-controls .string-set-buttons button:focus,
  .game-controls .quality-buttons button:focus,
  .game-controls .inversion-buttons button:focus, .game-controls .note-buttons button:focus {
    outline: none; }

.game-controls .chord-tone-legend,
.game-controls .scale-tone-legend,
.game-controls .mode-buttons,
.game-controls .string-set-buttons,
.game-controls .quality-buttons,
.game-controls .inversion-buttons {
  display: inline-block;
  background-color: rgba(1, 1, 1, 0.1);
  border-radius: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 2rem;
  vertical-align: top;
  min-height: 130px; }
  .game-controls .chord-tone-legend h3,
  .game-controls .scale-tone-legend h3,
  .game-controls .mode-buttons h3,
  .game-controls .string-set-buttons h3,
  .game-controls .quality-buttons h3,
  .game-controls .inversion-buttons h3 {
    margin-bottom: 0.5rem; }
  .game-controls .chord-tone-legend button,
  .game-controls .scale-tone-legend button,
  .game-controls .mode-buttons button,
  .game-controls .string-set-buttons button,
  .game-controls .quality-buttons button,
  .game-controls .inversion-buttons button {
    padding: 0.4rem;
    font-size: 1.3rem; }
    .game-controls .chord-tone-legend button.string-set-button,
    .game-controls .scale-tone-legend button.string-set-button,
    .game-controls .mode-buttons button.string-set-button,
    .game-controls .string-set-buttons button.string-set-button,
    .game-controls .quality-buttons button.string-set-button,
    .game-controls .inversion-buttons button.string-set-button {
      padding: 0.2rem; }
    .game-controls .chord-tone-legend button img,
    .game-controls .scale-tone-legend button img,
    .game-controls .mode-buttons button img,
    .game-controls .string-set-buttons button img,
    .game-controls .quality-buttons button img,
    .game-controls .inversion-buttons button img {
      margin-top: 0.1rem; }

.game-controls .scale-tone-legend span,
.game-controls .chord-tone-legend span {
  display: inline-block;
  margin: 0.2rem 0.5rem;
  margin-top: 2.5rem;
  position: relative; }
  .game-controls .scale-tone-legend span:after,
  .game-controls .chord-tone-legend span:after {
    content: ' ';
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    top: -2.4em;
    left: 50%;
    margin-left: -1rem; }
  .game-controls .scale-tone-legend span.root:after,
  .game-controls .chord-tone-legend span.root:after {
    background-color: #edae49; }
  .game-controls .scale-tone-legend span.second:after,
  .game-controls .chord-tone-legend span.second:after {
    background-color: #dd213d; }
  .game-controls .scale-tone-legend span.third:after,
  .game-controls .chord-tone-legend span.third:after {
    background-color: #e8999b; }
  .game-controls .scale-tone-legend span.fourth:after,
  .game-controls .chord-tone-legend span.fourth:after {
    background-color: #dfea0b; }
  .game-controls .scale-tone-legend span.fifth:after,
  .game-controls .chord-tone-legend span.fifth:after {
    background-color: #034078; }
  .game-controls .scale-tone-legend span.sixth:after,
  .game-controls .chord-tone-legend span.sixth:after {
    background-color: #aa19c4; }
  .game-controls .scale-tone-legend span.seventh:after,
  .game-controls .chord-tone-legend span.seventh:after {
    background-color: #00798c; }

.game-controls .note-buttons {
  margin-top: 2rem; }
  .game-controls .note-buttons button {
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    font-size: 1.5rem; }
    .game-controls .note-buttons button[disabled] {
      opacity: 0.8; }
    .game-controls .note-buttons button:not(.accidental) {
      position: relative;
      top: 5rem; }
    .game-controls .note-buttons button:not([disabled]):hover {
      background: #f0f0f0; }
    .game-controls .note-buttons button.accidental {
      background: #000;
      color: #fff;
      position: absolute;
      margin-left: -2rem; }
      .game-controls .note-buttons button.accidental[disabled] {
        background: #262626;
        color: darkgrey; }
      .game-controls .note-buttons button.accidental:not([disabled]):hover {
        background: #1a1a1a; }

.header-container {
  align-items: center;
  justify-content: space-between;
  margin: 1rem 5rem;
  display: flex;
  width: 100%; }

.logo {
  margin: 20px 0;
  max-width: 200px; }

.menu {
  display: flex; }
  .menu li {
    border-radius: 0.5rem;
    cursor: pointer;
    display: block;
    list-style: none;
    padding: 1rem; }
    .menu li:not(:last-child) {
      margin-right: 2rem; }
    .menu li.active {
      background: #d8d8d8; }
    .menu li:hover {
      background: #c4c4c4; }

.main {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 92rem; }

.fretboard-container {
  margin-top: 3rem;
  width: 100%;
  overflow-x: scroll; }

.fretboard {
  background-image: linear-gradient(#5a2523, #5c312f, #470a07);
  border-left: 1px solid black;
  width: 92rem; }

.highlighted-note::after {
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.4) -1px 3px;
  content: '';
  height: 2rem;
  left: calc(50% - 1rem);
  position: absolute;
  top: calc(50% - 1rem);
  width: 2rem; }

.highlighted-note.root::after {
  background: #edae49; }

.highlighted-note.third::after {
  background: #e8999b; }

.highlighted-note.fifth::after {
  background: #034078; }

.highlighted-note.seventh::after {
  background: #00798c; }

.highlighted-note.chord-octave::after {
  background: RGBA(195, 255, 48, 0.4); }

.highlighted-note.octave::after {
  background: RGBA(195, 255, 48, 0.4); }

.highlighted-note.selected::after {
  background: rgba(195, 255, 48, 0.85); }

.string-container {
  align-items: center;
  display: flex; }
  .string-container:first-child {
    border-top: 1px solid black; }
  .string-container:last-child {
    border-bottom: 1px solid black; }
  .string-container .string-name {
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    margin: 0 1rem;
    position: relative;
    width: 1.2rem; }
    .string-container .string-name span {
      position: relative;
      z-index: 5; }
  .string-container .string {
    display: flex; }

.fret {
  align-items: center;
  border-left: 1px solid #bdbcc0;
  border-right: 1px solid #bdbcc0;
  display: flex;
  height: 3rem;
  position: relative;
  width: 6rem; }
  .fret.fret-marker::after, .fret.fret-marker:nth-child(12)::before {
    content: '';
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #f8f8dc;
    position: absolute;
    left: calc(50% - 20px / 2);
    top: 2.5rem; }
  .fret:nth-child(12).fret-marker::after, .fret.fret-marker:nth-child(12)::before {
    top: 5.5rem; }
  .fret:nth-child(12).fret-marker::before {
    top: -0.5rem; }

.string-line {
  background-image: linear-gradient(#666666, #d0d0d0, #666666);
  box-shadow: -1px 8px 12px 1px rgba(0, 0, 0, 0.2);
  height: 0.5rem;
  position: absolute;
  width: 102%;
  z-index: 1; }
  .string-line.clickable:hover {
    background-image: linear-gradient(#333, #fff, #fff, #333);
    cursor: pointer; }
  .string-line .selected-note-name {
    color: #000;
    font-weight: bold;
    position: absolute;
    top: -0.4rem;
    width: 100%;
    z-index: 1; }

.btn, .primary-btn, .pick-note-btn, .danger-btn, .show-answer-btn {
  border-radius: 0.4rem;
  border: none;
  font-size: 2rem;
  font-weight: 500;
  height: 4rem;
  margin-top: 2rem; }

.primary-btn, .pick-note-btn {
  background-color: #7cd6ff;
  color: #fff; }
  .primary-btn:hover, .pick-note-btn:hover {
    background-color: #96deff; }

.danger-btn, .show-answer-btn {
  background-color: #ffaa91;
  color: #fff; }
  .danger-btn:hover, .show-answer-btn:hover {
    background-color: #ffbeab; }

.pick-note-btn {
  background: url(../public/dice.svg) no-repeat #7cd6ff;
  background-position: 1rem 0.75rem;
  background-size: 2.5rem;
  padding: 0 1.9rem 0 4rem; }

.show-answer-btn {
  background: url(../public/reveal.svg) no-repeat #ffaa91;
  background-position: 0.75rem;
  background-size: 2.5rem;
  padding-left: 3.7rem; }

.selected-note-info {
  margin: 2rem auto;
  text-align: left;
  width: 7.2rem; }
  .selected-note-info .label {
    width: 6rem;
    display: inline-block; }
  .selected-note-info .note-name {
    font-size: 3.5rem;
    font-weight: bold;
    text-align: center; }
  .selected-note-info .title {
    font-weight: bold; }

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

@-webkit-keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.reset-button {
  display: inline-block; }
  .reset-button button {
    text-decoration: underline;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-left: 1rem;
    outline: none;
    border: none;
    font-size: 0.8rem;
    background-color: transparent;
    color: #fff; }

.game-feedback {
  border-radius: 0 0 0.5rem 0.5rem;
  color: white;
  font-weight: 600;
  padding: 0.5rem; }
  .game-feedback.incomplete {
    background: #034078; }
  .game-feedback.correct {
    background: #50a350; }
  .game-feedback.incorrect {
    -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    background: #cd5858;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }

.toggle-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer; }
  .toggle-label .label-text {
    margin-left: 8px; }
  .toggle-label .toggle {
    isolation: isolate;
    position: relative;
    height: 24px;
    width: 48px;
    border-radius: 15px;
    background: #d6d6d6;
    overflow: hidden; }
  .toggle-label .toggle-inner {
    z-index: 2;
    position: absolute;
    top: 1px;
    left: 1px;
    height: 22px;
    width: 46px;
    border-radius: 15px;
    overflow: hidden; }
  .toggle-label .active-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    background: #2196f3;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    transition: -webkit-transform 0.05s linear 0.17s;
    transition: transform 0.05s linear 0.17s;
    transition: transform 0.05s linear 0.17s, -webkit-transform 0.05s linear 0.17s; }
  .toggle-label .toggle-state {
    display: none; }
  .toggle-label .indicator {
    height: 100%;
    width: 200%;
    background: #fff;
    border-radius: 13px;
    -webkit-transform: translate3d(-75%, 0, 0);
            transform: translate3d(-75%, 0, 0);
    transition: -webkit-transform 0.35s cubic-bezier(0.85, 0.05, 0.18, 1.35);
    transition: transform 0.35s cubic-bezier(0.85, 0.05, 0.18, 1.35);
    transition: transform 0.35s cubic-bezier(0.85, 0.05, 0.18, 1.35), -webkit-transform 0.35s cubic-bezier(0.85, 0.05, 0.18, 1.35); }
  .toggle-label .toggle-state:checked ~ .active-bg {
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0); }
  .toggle-label .toggle-state:checked ~ .toggle-inner .indicator {
    -webkit-transform: translate3d(25%, 0, 0);
            transform: translate3d(25%, 0, 0); }
